@font-face {
  font-family: "Aftika";
  src: local("Aftika"),
    url("../public/fonts/Aftika-Black.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Aftika";
  src: local("Aftika"),
    url("../public/fonts/Aftika-ExtraBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Aftika";
  src: local("Aftika"),
    url("../public/fonts/Aftika-Bold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Aftika";
  src: local("Aftika"),
    url("../public/fonts/Aftika-Regular.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Aftika";
  src: local("Aftika"),
    url("../public/fonts/Aftika-Light.otf") format("opentype");
  font-weight: 300;
}
        @font-face {
          font-family: "Aftika";
          src: local("Aftika"),
    url("../public/fonts/Aftika-ExtraLight.otf") format("opentype");
  font-weight: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Aftika";
    src: local("Aftika"),
      url("../public/fonts/Aftika-Black.otf") format("opentype");
    font-weight: 900;
  }

  @font-face {
    font-family: "Aftika";
    src: local("Aftika"),
      url("../public/fonts/Aftika-ExtraBold.otf") format("opentype");
    font-weight: 800;
  }

  @font-face {
    font-family: "Aftika";
    src: local("Aftika"),
      url("../public/fonts/Aftika-Bold.otf") format("opentype");
    font-weight: 600;
  }

  @font-face {
    font-family: "Aftika";
    src: local("Aftika"),
      url("../public/fonts/Aftika-Regular.otf") format("opentype");
    font-weight: 500;
  }

  @font-face {
    font-family: "Aftika";
    src: local("Aftika"),
      url("../public/fonts/Aftika-ExtraLight.otf") format("opentype");
    font-weight: normal;
  }

}
body {
  margin: 0;
  font-family: Aftika !important;
    -webkit-font-smoothing: Aftika !important;
    -moz-osx-font-smoothing: Aftika !important;
    background-color: #0e0e0e !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
