.bg-circle {
    fill: #1d1d1d;
    transition: all;
    transition-duration: 1s;
}

.forward-path {
    transition: all;
    transition-duration: 1s;
    fill: white
}

.social-btn:hover .bg-circle {
    fill: white;
}

.social-btn:hover .forward-path {
    fill: #1d1d1d;
}