.aboutus-title {
    position: relative;
    padding-right: 12px;
    border-bottom-right-radius: 60px;
}

.aboutus-title::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: 25px;
    right: -40px;
    z-index: 1;
}

.aboutus-detail {
    position: relative;
}

.aboutus-detail::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: 0px;
    right: -40px;
    z-index: 12;
}

.aboutus-detail::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: 25px;
    right: -40px;
    z-index: 1;
}

.aboutus-bottom {}

.aboutus-bottom::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: 20px 19px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: 0;
    left: -15px;
    z-index: 1;
}

.aboutus-bottom::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: 20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: -15px;
    right: 0;
    z-index: 1;
}

.review-detail {
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
        /* font-size: 11pt; */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
        text-overflow: hidden;
        box-sizing: border-box;
}