.footer-section {
    color: white;
    overflow: hidden;
}

.footer-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.footer-section:hover::before {
    transform: translate3d(0, -100%, 0);
}