.header {
    position: fixed;
    top: 0;
    z-index: 999;
    -webkit-backface-visibility: hidden;
}

.header-video {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -3;
}

.header-logo {
    text-align: left;
        /* height: 118px;
    min-height: 118px;
    max-height: 118px;
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    -webkit-mask-image: url('../assets/laconic-mask.png');
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-composite: source-out;
    mask-image: url('../assets/laconic-mask.png');
    mask-size: 100% 100%;
    mask-mode: luminance;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-composite: exclude;
        */

}
.logoTitle {
    line-height: 80px;
    font-weight: 800;
    background: url('../assets/bg_new.gif');
    color: #de466c;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.logoIcon {
    height: 70px;
}

.header-btn {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header-btn-active {
    opacity: 0.7 !important;
}
.header-icon {
    display: block;
    width: 50px;
        height: 50px;
}

.header-hover-icon {
    display: none;
    width: 60px;
        height: 60px;
}

.header-btn-active .header-hover-icon {
    display: block !important;
}

.header-btn-active .header-icon {
    display: none !important;
}
.header-IconBtn {
    margin-top: 8px;
    color: white;
    text-transform: uppercase;
    font-size: 8.75pt !important;
        font-weight: 600 !important;
    }
    
    .header-mobileIcon {
        width: 40px;
        height: 40px;
    }
    
    .header-mobile-IconBtn {
        margin-top: 8px;
        color: white;
        text-transform: uppercase;
        font-size: 0.5rem !important;
}
.aboutus-btn {
    position: fixed;
    color: white;

    overflow: hidden;
}

.aboutus-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 55px;
        border-top-right-radius: 55px;
    background: #1d1d1d !important;
    transition: all 0.3s cubic-bezier(0.7, 0, 0.2, 1) !important;
        -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.2, 1) !important;
}

.aboutus-btn:hover::before {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
        /* clip-path: inset(0px 0px 100% 0px); */
}
.aboutus-btn:hover {
    background-color: white;
}
.faq-btn {
    position: fixed;
    color: white;
    top: 81px;
    overflow: hidden;
}

.faq-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 55px;
        border-bottom-right-radius: 55px;
    background: #1d1d1d !important;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1) !important;
    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.2, 1) !important;
}

.faq-btn:hover::before {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, -100%, 0);
}

.faq-btn:hover {
    background-color: white;
}
.active-btn {
    background-color: white;

}

.active-btn::before {
    -webkit-transform: translate3d(0, 100%, 0) !important;
    transform: translate3d(0, 100%, 0) !important;
}