.reach-top-left {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 30px;
}

.reach-top-left::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: 25px;
    right: -40px;
    z-index: 0;
}

.reach-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-left: 30px;
    padding-top: 30px;
    border-top-left-radius: 20px;
}

.reach-bottom-right::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: 20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: -15px;
    right: 0px;
    z-index: 0;
}

.reach-bottom-right::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: 20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: 0px;
    left: -15px;
    z-index: 0;
}

.touch-click {
    position: relative;
    border-radius: 10px;
    background: #1d1d1d;
    text-transform: uppercase;
    color: white;
    overflow: hidden;
}

.touch-click::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 10px;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.touch-click:hover::before {
    transform: translate3d(0, -100%, 0);
}

.reach-contact {
    width: 100%;
    }
    
    .reach-contact::after {
        content: "";
        width: 40px;
        height: 40px;
        border-radius: 13px;
        box-shadow: 20px 20px 0px 0 #0e0e0e;
        margin-left: -25px;
        margin-top: -25px;
    position: absolute;
    top: -15px;
        right: 0px;
        z-index: 0;
}

.reach-contact::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: -15px;
    left: 25px;
    z-index: 0;
}
.reach-form-element {
    background: #1d1d1b;
    border: 1px solid white;
    color: white;
    padding: 2px 4px;
}

.reachout-send-btn {
    position: relative;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    text-transform: uppercase;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background: #1d1d1d;
    color: white;
    overflow: hidden;
}


.reachout-send-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.reachout-send-btn:hover::before {
    transform: translate3d(0, -100%, 0);
}
.reach-label-text {
    opacity: 0;
}

.reach-mobile-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0e0e0e2e;
}

.reach-mobile-top {
    position: relative;
}

.reach-mobile-top::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: 20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: -40px;
    right: 1px;
    z-index: 11;
}

.reach-mobile-top::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px -20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    bottom: -40px;
    left: 26px;
    z-index: 0;
}

.reach-mobile-bottom {
    position: relative;
}

.reach-mobile-bottom::after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: 20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: -15px;
    right: 1px;
    z-index: 11;
}

.reach-mobile-bottom::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 13px;
    box-shadow: -20px 20px 0px 0 #0e0e0e;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: -15px;
    left: 26px;
    z-index: 0;
}