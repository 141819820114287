@keyframes rotatesun {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(180deg);
    }
}

#sunGroup {
    transform-origin: 66%;
    animation-fill-mode: forwards;
    animation-range: cover;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-name: rotatesun;
    animation-iteration-count: infinite;
}